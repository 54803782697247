<template>
  <v-container>
    <v-row class="align-center justify-center">
      <v-col cols="2" class="pa-1">
        <span> Tên trang </span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <ValidationProvider name="name" rules="required" v-slot="{ errors }">
          <hnr-input
            :text="detailMenuFooter.name"
            v-model="detailMenuFooter.name"
            @input="onKeyUp"
          ></hnr-input>
          <span class="validate-error text-danger text-danger">
            {{ errors[0] }}
          </span>
        </ValidationProvider>
      </v-col>
    </v-row>
    <v-row class="align-center justify-center">
      <v-col cols="2" class="pa-1">
        <span> Slug </span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <ValidationProvider name="slug" rules="required" v-slot="{ errors }">
          <hnr-input :text="slug" v-model="detailMenuFooter.slug"></hnr-input>
          <span class="validate-error text-danger text-danger">
            {{ errors[0] }}
          </span>
        </ValidationProvider>
      </v-col>
    </v-row>
    <v-row class="align-center justify-center">
      <v-col cols="2" class="pa-1">
        <span> Nội dung trang </span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <ck-content
          id="lesson_content"
          :content="detailMenuFooter.content"
          @getData="updateContent"
          v-model="detailMenuFooter.content"
        ></ck-content>
      </v-col>
    </v-row>
    <v-row class="align-center justify-center" style="padding-top: 30px">
      <v-col cols="2" class="pa-1">
        <span> Sắp xếp </span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <hnr-input
            :text="convertNumberToString(detailMenuFooter.order)"
            @input="update"
            v-model="detailMenuFooter.order"
        ></hnr-input>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import HnrInput from "@/components/elements/hnr-input";
import mixin from "@/store/mixin";
import CkContent from "@/components/ck-content.vue";
export default {
  mixins: [mixin],
  props: {
    menufooter: Object,
    isNew: { type: Boolean, default: false },
  },
  components: {
    CkContent,
    HnrInput,
  },
  data() {
    return {
      show: true,
      detailMenuFooter: {},
      name: "",
      slug: "",
    };
  },

  watch: {
    menufooter: {
      deep: true,
      immediate: true,
      handler(newVal) {
        this.detailMenuFooter = newVal;
      },
    },
    detailMenuFooter: {
      deep: true,
      immediate: true,
      handler(newVal) {
        this.slug = newVal.slug;
      },
    },
  },
  methods: {
    onKeyUp(value) {
      this.detailMenuFooter.slug = this.sanitizeTitle(value);
    },
    updateContent(val) {
      this.detailMenuFooter.content = val;
    },
    isRealValue(obj) {
      return obj && obj !== 'null' && obj !== 'undefined';
    }
    ,
    convertNumberToString(number) {
      if (this.isRealValue(number)) {
        return number.toString();
      }
      return "0";
    },
    update() {
      this.$emit("input");
    },
  },
};
</script>
