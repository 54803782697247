<template>
    <v-container>
      <ValidationObserver v-slot="{ handleSubmit }">
        <v-tabs v-model="tabIndex">
          <v-tab v-for="item in items" :key="item.id">{{ item.tab }}</v-tab>
        </v-tabs>
        <v-tabs-items class="tab-content" v-model="tabIndex">
          <v-tab-item active>
            <menu-footer-content
                class="tab-detail"
                :menufooter="detailMenuFooter"
                :isNew="isCreate"
            ></menu-footer-content>
          </v-tab-item>
        </v-tabs-items>
        <v-row v-if="isCreate" class="align-center justify-center ma-0 pt-5">
          <v-btn color="primary" @click="handleSubmit(saveSubject)">Tạo trang</v-btn>
        </v-row>
        <v-row v-else class="align-center justify-center ma-0 pt-5">
          <v-btn color="primary" @click="handleSubmit(updateSubject)">Cập nhật trang</v-btn>
        </v-row>
      </ValidationObserver>
    </v-container>
  </template>
  
  <script>
  import MenuFooterContent from "@/components/menu-footer/MenuFooterContent.vue";
  
  import {mapGetters, mapState} from "vuex";
  import {SET_BREADCRUMB} from "@/store/breadcrumbs.module";
  import {SET_LOADING} from "@/store/loading.module";
  
  import {SAVE_MENU_FOOTER, GET_DETAIL_MENU_FOOTER, UPDATE_MENU_FOOTER} from "@/store/menufooter.module";
  import {SUCCESS, ERROR} from "@/store/alert.module";
  
  export default {
    data() {
      return {
        id: "",
        isCreate: true,
        tabIndex: 0,
        items: [{id: 0, tab: "Thông tin trang"}],
        headers: [
          {
            text: "id",
            value: "id",
          },
          {text: "Tên Chuyên mục", value: "name"},
        ],
        detailMenuFooter: {
          id: '',
          name: "",
          slug: "",
          content: "",
          order: ""
        },
      };
    },
    components: {
      MenuFooterContent,
    },
    computed: {
      ...mapGetters({
        menufooter: "detailMenuFooter",
      }),
    },
    watch: {
      menufooter: {
        deep: true,
        immediate: true,
        handler(newVal) {
          this.detailMenuFooter = newVal;
        },
      },
    },
    created() {
      this.id = this.$route.query.id;
    },
    mounted() {
      if (this.id) {
        this.isCreate = false;
        this.$store.dispatch(SET_BREADCRUMB, [{title: "Sửa trang"}]);
  
        this.$store.dispatch(SET_LOADING, true);
        this.$store
            .dispatch(GET_DETAIL_MENU_FOOTER, this.id)
            .then(() => {
              this.$store.dispatch(SET_LOADING, false);
            })
            .catch(() => {
              this.$store.dispatch(SET_LOADING, false);
            });
      } else {
        this.isCreate = true;
        this.$store.dispatch(SET_BREADCRUMB, [{title: "Thêm trang"}]);
      }
    },
    methods: {
      updateSubject() {
        let payload = {
          id: this.id,
          name: this.detailMenuFooter.name,
          content: this.detailMenuFooter.content,
          slug: this.detailMenuFooter.slug,
          order: this.detailMenuFooter.order,
        };
        this.$store.dispatch(SET_LOADING, true);
        this.$store
            .dispatch(UPDATE_MENU_FOOTER, payload)
            .then((data) => {
              if (data) {
                this.$store.dispatch(SUCCESS, "Cập nhật trang thành công!", {
                  root: true,
                });
                this.$router.push("/menu-footer");
                this.detailMenuFooter = {}
              } else {
                if (data.errorCode == 424) {
                  this.$store.dispatch(ERROR, data.message, {
                    root: true,
                  });
                } else {
                  this.$store.dispatch(ERROR, data.message, {
                    root: true,
                  });
                }
              }
              this.$store.dispatch(SET_LOADING, false);
            })
            .catch(() => {
              this.$store.dispatch(ERROR, "Cập nhật trang thất bại!", {
                root: true,
              });
              this.$store.dispatch(SET_LOADING, false);
            });
      },
      saveSubject() {
        let payload = {
          name: this.detailMenuFooter.name,
          content: this.detailMenuFooter.content ?? '',
          slug: this.detailMenuFooter.slug,
          order: this.detailMenuFooter.order,
        };
        this.$store.dispatch(SET_LOADING, true);
        this.$store
            .dispatch(SAVE_MENU_FOOTER, payload)
            .then((data) => {
              if (data.status) {
                this.$store.dispatch(SUCCESS, "Thêm trang thành công!", {
                  root: true,
                });
                this.$router.push("/menu-footer");
              } else {
                this.$store.dispatch(ERROR, data.message, {
                  root: true,
                });
              }
              this.$store.dispatch(SET_LOADING, false);
            })
            .catch(() => {
              this.$store.dispatch(ERROR, "Thêm trang thất bại!", {root: true});
              this.$store.dispatch(SET_LOADING, false);
            });
      },
    },
  };
  </script>
  
  <style lang="scss">
  .tab-detail {
    margin-top: 15px;
  }
  </style>
  